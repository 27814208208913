.Track {
	--setup-width: 30vw;
	--waveform-width: 30vw;
	--name-height: 40px;
	--waveform-height: 140px;
	--track-height: calc(var(--name-height) + var(--waveform-height));
	/* min-width: calc(var(--waveform-width) + var(--setup-width)); */
	margin: 30px 0;
	position: relative;
	display: grid;
	grid-template-rows: [top] var(--name-height) [middle] var(--waveform-height) [bottom];
	grid-template-columns: [clips] auto [divider] var(--setup-width) [setup];
}
.Track__name {
	position: sticky;
	left: 0;
	height: var(--name-height);
	line-height: var(--name-height);
	margin-right: var(--setup-width);
	user-select: text;
	background: gold;
	padding-left: 20px;
	width: 100%;
	box-sizing: border-box;
	grid-row: top / middle;
	grid-column: clips / divider;
}
.Track.isRecording .Track__name {
	background: red;
}
.Track__clips {
	grid-row: middle / bottom;
	grid-column: clips / divider;
	/* min-width: var(--waveform-width); */
	/* height: var(--waveform-height); */
	white-space: nowrap;
}
.Track__setup {
	grid-row: middle / bottom;
	grid-column: divider / setup;
	background: #eee;
	/* width: var(--setup-width); */
	padding: 10px;
	box-sizing: border-box;
	/* background: aqua; */
}
.isRecording .Track__setup {
	color: white;
}

.Track__sources {
	--size: 30px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: calc(var(--size) * 2);
}
.Track__source {
	width: var(--size);
	height: var(--size);
	background: gainsboro;
	box-sizing: border-box;
	color: white;
	text-align: center;
	line-height: var(--size);
	border: 1px solid transparent;
	cursor: pointer;
}
.Track__source:hover {
	border-color: white;
}
.isActive {
	background: skyblue;
}
