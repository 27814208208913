.root {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(127, 127, 127, 1);
}


.node {
	background: lightgray;
	width: 100px;
	height: 100px;
	position: absolute;
	top: 0;
	left: 0;
}

.node:hover {
	outline: 4px solid black;
	z-index: 100;
}
