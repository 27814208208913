.Interface {
	display: flex;
	height: 100vh;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	cursor: default;
	user-select: none;
}

.Interface__toolbar {
	margin: 0 0;
}
.Interface__timeline {
	margin: auto 0;
	width: 100%;
	flex: 1;
}
