:root {
	--black: #000;
	--white: #fff;
}

html, body {
	margin: 0;
	padding: 0;
	background: var(--white);
	color: var(--black);
	height: 100%;
	overflow: hidden;
}

body {
	font-family: sans-serif;
}

:global(#app) {
	min-height: 100%;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1;
	margin: 0;
	padding: 0;
	font-weight: normal;
}
